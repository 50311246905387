// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-case-study-js": () => import("./../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-industries-js": () => import("./../src/templates/industries.js" /* webpackChunkName: "component---src-templates-industries-js" */),
  "component---src-templates-resources-case-studies-js": () => import("./../src/templates/resources-case-studies.js" /* webpackChunkName: "component---src-templates-resources-case-studies-js" */),
  "component---src-templates-services-js": () => import("./../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-solutions-post-js": () => import("./../src/templates/solutions-post.js" /* webpackChunkName: "component---src-templates-solutions-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-cookie-policy-js": () => import("./../src/pages/about/cookie-policy.js" /* webpackChunkName: "component---src-pages-about-cookie-policy-js" */),
  "component---src-pages-about-privacy-policy-js": () => import("./../src/pages/about/privacy-policy.js" /* webpackChunkName: "component---src-pages-about-privacy-policy-js" */),
  "component---src-pages-campaigns-index-js": () => import("./../src/pages/campaigns/index.js" /* webpackChunkName: "component---src-pages-campaigns-index-js" */),
  "component---src-pages-campaigns-view-campaign-js": () => import("./../src/pages/campaigns/view-campaign.js" /* webpackChunkName: "component---src-pages-campaigns-view-campaign-js" */),
  "component---src-pages-case-study-index-js": () => import("./../src/pages/case-study/index.js" /* webpackChunkName: "component---src-pages-case-study-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-customers-index-js": () => import("./../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-demos-custom-demo-js": () => import("./../src/pages/demos/custom-demo.js" /* webpackChunkName: "component---src-pages-demos-custom-demo-js" */),
  "component---src-pages-demos-index-js": () => import("./../src/pages/demos/index.js" /* webpackChunkName: "component---src-pages-demos-index-js" */),
  "component---src-pages-demos-manage-demo-modal-js": () => import("./../src/pages/demos/ManageDemoModal.js" /* webpackChunkName: "component---src-pages-demos-manage-demo-modal-js" */),
  "component---src-pages-industries-banking-insurance-js": () => import("./../src/pages/industries/banking-insurance.js" /* webpackChunkName: "component---src-pages-industries-banking-insurance-js" */),
  "component---src-pages-industries-communications-services-js": () => import("./../src/pages/industries/communications-services.js" /* webpackChunkName: "component---src-pages-industries-communications-services-js" */),
  "component---src-pages-industries-index-del-js": () => import("./../src/pages/industries/index-del.js" /* webpackChunkName: "component---src-pages-industries-index-del-js" */),
  "component---src-pages-industries-index-js": () => import("./../src/pages/industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-industries-industrial-manufacturing-js": () => import("./../src/pages/industries/industrial-manufacturing.js" /* webpackChunkName: "component---src-pages-industries-industrial-manufacturing-js" */),
  "component---src-pages-industries-retail-distribution-js": () => import("./../src/pages/industries/retail-distribution.js" /* webpackChunkName: "component---src-pages-industries-retail-distribution-js" */),
  "component---src-pages-login-index-js": () => import("./../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-register-index-js": () => import("./../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-resources-case-studies-js": () => import("./../src/pages/resources/case-studies.js" /* webpackChunkName: "component---src-pages-resources-case-studies-js" */),
  "component---src-pages-resources-featured-demos-js": () => import("./../src/pages/resources/featured-demos.js" /* webpackChunkName: "component---src-pages-resources-featured-demos-js" */),
  "component---src-pages-resources-index-js": () => import("./../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-services-ai-assistant-services-js": () => import("./../src/pages/services/ai-assistant-services.js" /* webpackChunkName: "component---src-pages-services-ai-assistant-services-js" */),
  "component---src-pages-services-applications-development-js": () => import("./../src/pages/services/applications-development.js" /* webpackChunkName: "component---src-pages-services-applications-development-js" */),
  "component---src-pages-services-enterprise-bi-and-analytics-js": () => import("./../src/pages/services/enterprise-bi-and-analytics.js" /* webpackChunkName: "component---src-pages-services-enterprise-bi-and-analytics-js" */),
  "component---src-pages-services-gen-ai-services-js": () => import("./../src/pages/services/gen-ai-services.js" /* webpackChunkName: "component---src-pages-services-gen-ai-services-js" */),
  "component---src-pages-services-index-del-js": () => import("./../src/pages/services/index-del.js" /* webpackChunkName: "component---src-pages-services-index-del-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-planning-and-budgeting-js": () => import("./../src/pages/services/planning-and-budgeting.js" /* webpackChunkName: "component---src-pages-services-planning-and-budgeting-js" */),
  "component---src-pages-services-professional-services-js": () => import("./../src/pages/services/professional-services.js" /* webpackChunkName: "component---src-pages-services-professional-services-js" */),
  "component---src-pages-services-professional-services-advanced-analytics-js": () => import("./../src/pages/services/professional-services/advanced-analytics.js" /* webpackChunkName: "component---src-pages-services-professional-services-advanced-analytics-js" */),
  "component---src-pages-services-professional-services-advanced-visualizations-js": () => import("./../src/pages/services/professional-services/advanced-visualizations.js" /* webpackChunkName: "component---src-pages-services-professional-services-advanced-visualizations-js" */),
  "component---src-pages-services-professional-services-cognos-analytics-js": () => import("./../src/pages/services/professional-services/cognos-analytics.js" /* webpackChunkName: "component---src-pages-services-professional-services-cognos-analytics-js" */),
  "component---src-pages-services-professional-services-data-warehousing-js": () => import("./../src/pages/services/professional-services/data-warehousing.js" /* webpackChunkName: "component---src-pages-services-professional-services-data-warehousing-js" */),
  "component---src-pages-services-professional-services-planning-analytics-js": () => import("./../src/pages/services/professional-services/planning-analytics.js" /* webpackChunkName: "component---src-pages-services-professional-services-planning-analytics-js" */),
  "component---src-pages-services-watson-assistant-services-js": () => import("./../src/pages/services/watson-assistant-services.js" /* webpackChunkName: "component---src-pages-services-watson-assistant-services-js" */),
  "component---src-pages-services-watson-discovery-services-js": () => import("./../src/pages/services/watson-discovery-services.js" /* webpackChunkName: "component---src-pages-services-watson-discovery-services-js" */),
  "component---src-pages-services-watsonx-orchestrate-js": () => import("./../src/pages/services/watsonx-orchestrate.js" /* webpackChunkName: "component---src-pages-services-watsonx-orchestrate-js" */),
  "component---src-pages-services-watsonx-platform-services-js": () => import("./../src/pages/services/watsonx-platform-services.js" /* webpackChunkName: "component---src-pages-services-watsonx-platform-services-js" */),
  "component---src-pages-solutions-ai-for-customer-care-js": () => import("./../src/pages/solutions/ai-for-customer-care.js" /* webpackChunkName: "component---src-pages-solutions-ai-for-customer-care-js" */),
  "component---src-pages-solutions-ai-for-employee-care-js": () => import("./../src/pages/solutions/ai-for-employee-care.js" /* webpackChunkName: "component---src-pages-solutions-ai-for-employee-care-js" */),
  "component---src-pages-solutions-human-resource-services-js": () => import("./../src/pages/solutions/human-resource-services.js" /* webpackChunkName: "component---src-pages-solutions-human-resource-services-js" */),
  "component---src-pages-solutions-index-del-js": () => import("./../src/pages/solutions/index-del.js" /* webpackChunkName: "component---src-pages-solutions-index-del-js" */),
  "component---src-pages-solutions-index-js": () => import("./../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-it-service-desk-js": () => import("./../src/pages/solutions/it-service-desk.js" /* webpackChunkName: "component---src-pages-solutions-it-service-desk-js" */),
  "component---src-pages-solutions-mass-tort-service-js": () => import("./../src/pages/solutions/mass-tort-service.js" /* webpackChunkName: "component---src-pages-solutions-mass-tort-service-js" */),
  "component---src-pages-solutions-planning-analytics-service-js": () => import("./../src/pages/solutions/planning-analytics-service.js" /* webpackChunkName: "component---src-pages-solutions-planning-analytics-service-js" */),
  "component---src-pages-solutions-reseller-distributor-service-js": () => import("./../src/pages/solutions/reseller-distributor-service.js" /* webpackChunkName: "component---src-pages-solutions-reseller-distributor-service-js" */),
  "component---src-pages-solutions-sales-support-solutions-js": () => import("./../src/pages/solutions/sales-support-solutions.js" /* webpackChunkName: "component---src-pages-solutions-sales-support-solutions-js" */),
  "component---src-pages-why-incede-index-js": () => import("./../src/pages/why-incede/index.js" /* webpackChunkName: "component---src-pages-why-incede-index-js" */),
  "component---src-pages-why-incede-why-incede-sub-items-certified-expertise-js": () => import("./../src/pages/why-incede/why-incede-sub-items/CertifiedExpertise.js" /* webpackChunkName: "component---src-pages-why-incede-why-incede-sub-items-certified-expertise-js" */),
  "component---src-pages-why-incede-why-incede-sub-items-customer-success-js": () => import("./../src/pages/why-incede/why-incede-sub-items/CustomerSuccess.js" /* webpackChunkName: "component---src-pages-why-incede-why-incede-sub-items-customer-success-js" */),
  "component---src-pages-why-incede-why-incede-sub-items-leader-ship-team-js": () => import("./../src/pages/why-incede/why-incede-sub-items/LeaderShipTeam.js" /* webpackChunkName: "component---src-pages-why-incede-why-incede-sub-items-leader-ship-team-js" */),
  "component---src-pages-why-incede-why-incede-sub-items-results-in-business-terms-js": () => import("./../src/pages/why-incede/why-incede-sub-items/ResultsInBusinessTerms.js" /* webpackChunkName: "component---src-pages-why-incede-why-incede-sub-items-results-in-business-terms-js" */),
  "component---src-pages-why-incede-why-incede-sub-items-technology-partners-js": () => import("./../src/pages/why-incede/why-incede-sub-items/TechnologyPartners.js" /* webpackChunkName: "component---src-pages-why-incede-why-incede-sub-items-technology-partners-js" */)
}

